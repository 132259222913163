<script lang="ts" setup>
type backgroundColorType =
  | ''
  | 'white'
  | 'cream'
  | 'night'
  | 'arctic'
  | 'sky'
  | 'midnight'
  | 'venom'
  | 'jungle'
  | 'forest'

type textColorType = '' | 'white' | 'black'

defineProps<{
  infoText: string
  backgroundColor: backgroundColorType
  textColor: textColorType
}>()

const infoBarBackgroundColor = {
  white: 'bg-[#FFFFFF]',
  cream: 'bg-[#F8F5F3]',
  night: 'bg-[#111111]',
  arctic: 'bg-[#8FEAFF]',
  sky: 'bg-[#244BF5]',
  midnight: 'bg-[#031B7E]',
  venom: 'bg-[#C8FF83]',
  jungle: 'bg-[#017E42]',
  forest: 'bg-[#0C5235]',
  '': 'bg-[#F8F5F3]',
}

const infoBarTextColor = {
  white: 'text-[#FFFFFF]',
  black: 'text-[#000000]',
}
</script>

<template>
  <div
    :class="[infoBarBackgroundColor[backgroundColor], infoBarTextColor[textColor || 'black']]"
    class="mx-auto flex max-w-2xl justify-center text-ellipsis py-1 text-xs sm:rounded-b-md">
    {{ infoText }}
  </div>
</template>
